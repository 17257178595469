import userInfoService from "../../../../services/userInfoService";
import userGateWayStatusService from "../../../../services/userGateWayStatusService";
import { mapActions, mapGetters } from "vuex";
import * as types from "../../../../store/types";

export default {
  name: "merchant-id",
  components: {},
  props: [],
  data() {
    return {
      IPAddress: "",
      webSiteUrl: "",
      MerchantBussinesName: "",
      merchantID: localStorage.getItem("bitrahAccessToken"),
      merchantSlug: localStorage.getItem("bitrahAccessToken")
    };
  },
  computed: {
    ...mapGetters({
      userInfo: types.GET_USER_INFO,
      userGateway: types.GET_USER_GATEWAY_STATUS
    }),
    isValidUrl() {
      return this.webSiteUrl.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/g
      )
        ? true
        : false;
    },
    isValidIpAddress() {
      return this.IPAddress.match(
        /(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/g
      )
        ? true
        : false;
    }
  },
  mounted() {
    setTimeout(() => {
      this.getUserGatewayStatusData();
    }, 1300);
  },
  created() {
    this.getInfoData();
  },

  methods: {
    ...mapActions({
      userInfoAction: types.ACTION_USER_INFO,
      userGateWayAction: types.ACTION_USER_GATEWAY_STATUS
    }),
    onCopyToClipboard() {
      this.$bvToast.toast(this.$i18n.t("merchant.successfullCopy"), {
        title: this.$i18n.t("toast.successTitle"),
        noCloseButton: true,
        variant: "custom",
        bodyClass: "successFontColor rtl text-right font-weight-bold py-3",
        headerClass: "successBackgroundColor successFontColor rtl text-right",
        solid: false
      });
    },
    onErrorToClipboard() {
      this.$bvToast.toast(this.$i18n.t("merchant.failedCopy"), {
        title: this.$i18n.t("toast.errorTitle"),
        noCloseButton: true,
        variant: "custom",
        bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
        headerClass: "dangerBackgroundColor dangerFontColor rtl text-right",
        solid: false
      });
    },
    getUserGatewayStatusData(requestSent) {
      userGateWayStatusService
        .getUserGateWay(
          this.userInfo.username,
          this.IPAddress,
          this.MerchantBussinesName,
          this.webSiteUrl
        )
        .then(response => {
          this.userGateWayAction(response.data);

          if (requestSent) {
            this.$bvToast.toast(
              this.$i18n.t(
                "panel.merchant.dashboard.merchantInfo.successfulSubmitGateway"
              ),
              {
                title: this.$i18n.t("toast.successTitle"),
                noCloseButton: true,
                variant: "custom",
                bodyClass:
                  "successFontColor rtl text-right font-weight-bold py-3",
                headerClass:
                  "successBackgroundColor successFontColor  rtl text-right",
                solid: false
              }
            );
          }
        })
        .catch(response => {
          this.$bvToast.toast(response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            solid: false
          });
        })
        .finally(() => (this.loading = false));
    },
    checkForm() {
      if (
        this.IPAddress === "" ||
        this.MerchantBussinesName === "" ||
        this.webSiteUrl === ""
      ) {
        this.$bvToast.toast(
          this.$i18n.t("panel.merchant.dashboard.merchantInfo.checkEmpty"),
          {
            title: this.$i18n.t(
              "panel.merchant.dashboard.merchantInfo.toastTitle"
            ),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            solid: false
          }
        );
      } else if (!this.isValidUrl) {
        this.$bvToast.toast(
          this.$i18n.t("panel.merchant.dashboard.merchantInfo.checkUnvalidUrl"),
          {
            title: this.$i18n.t(
              "panel.merchant.dashboard.merchantInfo.toastTitle"
            ),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            solid: false
          }
        );
      } else if (!this.isValidIpAddress) {
        this.$bvToast.toast(
          this.$i18n.t(
            "panel.merchant.dashboard.merchantInfo.checkUnvalidIpAddress"
          ),
          {
            title: this.$i18n.t(
              "panel.merchant.dashboard.merchantInfo.toastTitle"
            ),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            solid: false
          }
        );
      } else {
        this.getUserGatewayStatusData("requestSent");
      }
    },
    getInfoData() {
      userInfoService
        .getUserInfo()
        .then(response => {
          this.userInfoAction(response.data);
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            solid: false
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
